import { base_url } from "../Services/Base";
import { createBrowserHistory  } from "history";

export const isAuthenticated = () => {
    
    if (localStorage.getItem('token')){
        return true;
    } 
    return false;
}

export const complete_profile = () => {
    return localStorage.getItem('complete_profile') === 'true';
}

export const login = (credentials) => {
    return fetch(`${base_url}user/login`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ email: credentials.email, password: credentials.password })
    }).then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                localStorage.setItem("token", data.token_type + " " + data.access_token);
                console.log("token storage");
            })
        }

        return res;
    })

}

export const register = (data) => {
    return fetch(`${base_url}user/register`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            email: data.email,
            name: data.name,
            birthdate: data.formated_birthdate,
            telephone: data.cellphone,
            sex: data.sex,
            password: data.password,
            password_confirmation: data.password_confirmation,
        })
    })

}

export const complete_register = (data) => {
    return fetch(`${base_url}user/complete_register`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "PUT",
        body: JSON.stringify({
            cellphone: data.cellphone,
            birthdate: data.formated_birthdate,
            sex: data.sex
        })
    })
}

export const exist = (data) => {
    return fetch(`${base_url}user/exist`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "POST",
        body: JSON.stringify({
            email: data.email,
            telephone: data.cellphone
        })
    })
}

export const password = (data) => {
    return fetch(`${base_url}user/password`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "POST",
        body: JSON.stringify({
            current_password: data.current_password,
            new_password: data.new_password,
            new_password_confirmation: data.new_password_confirmation
        })
    })

}

export const subscribe_notifications = (playerid) => {
    return fetch(`${base_url}subscribe/${playerid}`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "POST"
    })
}

export const get_user = () => {
    return fetch(`${base_url}user`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "GET"
    }).then(response => {
        if (response.status === 401) {
            const history = new createBrowserHistory()
            localStorage.removeItem('token');
            localStorage.removeItem('complete_profile');
            localStorage.removeItem('consent');
            history.go('/');
        }
        return response
    });

}

export const verify = (token) => {
    return fetch(`${base_url}user/verify/${token}`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "GET"
    })
}

export const login_facebook = (access_token) => {
    return fetch(`${base_url}user/facebook`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ access_token })
    })
}

export const login_google = (accessToken) => {
    return fetch(`${base_url}user/google`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ accessToken })
    })
}

export const logout = () => {
    return fetch(`${base_url}user/logout`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "GET"
    }).then(data => {
        localStorage.removeItem('token');
        localStorage.removeItem('complete_profile');
        localStorage.removeItem('consent');
        return data;
    })

}

export const forgot_password = (data) => {
    return fetch(`${base_url}user/forgot_password`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data)
    })
}

export const reset_password = (data) => {
    return fetch(`${base_url}user/reset_password`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data)
    })
}




